import { Model } from "../components/Model";
import { Header } from "../components/Header";
import { Details } from "../components/Details";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getInformation } from "../api/requests";
import { Button } from "@mui/material";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";

export const Information = () => {
  const location = useLocation();
  const locations = location.pathname.split("/");

  const [label, setLabel] = useState(locations[locations.length - 1]);
  const [information, setInformation] = useState(null);
  const [editable, setEditable] = useState(false);
  const [save, setSave] = useState(false);
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [add, setAdd] = useState(false);
  const [probability, setProbability] = useState(false);
  const [labelName, setLabelName] = useState();
  const [labelType, setLabelType] = useState();
  useEffect(() => {
    if (label) {
      const _label = label.split("&")[1];
      const _labelType = label.split("&")[0];
      const probability = label.split("&")[2];
      setProbability(probability);
      setLabelType(_labelType);
      const data = localStorage.getItem(_labelType + "&" + _label);
      if (data) {
        const item = JSON.parse(data);
        setInformation(item);
        setLabelName(item.label);
      } else {
        getInformation(_label, _labelType).then((data) => {
          localStorage.setItem(
            _labelType + "&" + _label,
            JSON.stringify(data.data)
          );
          setInformation(data.data);
          setLabelName(data.data.label);
        });
      }
    }
  }, [label]);

  return (
    <>
      <Header></Header>
      <div className={"information"}>
        <div className={"label-title"}>
          {labelName}
          <div
            style={{
              display: "flex",
              margin: 10,
              borderRadius: "100%",
              height: 40,
              width: 40,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: probability < 90 ? "yellow" : "green",
            }}
          >
            {probability}%
          </div>
        </div>
        <Model modelName={label.split("&")[1]}></Model>
        {information ? (
          <Details
            data={information}
            editable={editable}
            setEditable={setEditable}
            save={save}
            setSave={setSave}
            newKey={newKey}
            newValue={newValue}
            setNewKey={setNewKey}
            setNewValue={setNewValue}
            add={add}
            setAdd={setAdd}
            setNewInformations={setInformation}
          ></Details>
        ) : (
          ""
        )}

        <Button
          variant="contained"
          style={{
            margin: 10,
            width: "100%",
            border: "5px solid #fff",
            borderRadius: 10,
            backgroundColor: "#12977A",
          }}
          onClick={() => {
            if (editable) {
              if (newKey != "" && newValue != "") {
                const newInformations = { ...information, [newKey]: newValue };
                localStorage.setItem(
                  labelType + "&" + labelName,
                  JSON.stringify(newInformations)
                );
                setInformation(newInformations);
                setNewValue("");
                setNewKey("");
              } else {
                const newInformations = { ...information };
                localStorage.setItem(
                  labelType + "&" + labelName,
                  JSON.stringify(newInformations)
                );
                setInformation(newInformations);
              }
            }

            setEditable(!editable);
          }}
        >
          {editable ? (
            <>
              <SaveSharpIcon></SaveSharpIcon>
              <div style={{ marginLeft: "8px", display: "inline" }}>Save</div>
            </>
          ) : (
            <>
              <EditSharpIcon></EditSharpIcon>
              <div style={{ marginLeft: "8px", display: "inline" }}>Edit</div>
            </>
          )}
        </Button>
      </div>
    </>
  );
};
