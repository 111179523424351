import './App.css';
import {
    Routes, Route
} from "react-router-dom";
import {StartPage} from "./pages/StartPage";
import {Setting} from "./pages/Setting";
import {Scan2BIM} from "./pages/Scan2BIM/Scan2BIM";
import {Scan2Progress} from "./pages/Scan2Progress/Scan2Progress";
import {Information} from "./pages/Information";


function App() {
    return (<div className="App">
        <Routes>
            <Route path="/" element={<StartPage></StartPage>}/>
            <Route path="/settings" element={<Setting></Setting>}/>
            <Route path="/scan2BIM" element={<Scan2BIM></Scan2BIM>}/>
            <Route path="/scan2Progress" element={<Scan2Progress></Scan2Progress>}/>
            <Route path="/information/:id" element={<Information></Information>}/>

        </Routes>
    </div>);
}

export default App;
