import Button from "@mui/material/Button"
import {useNavigate} from "react-router-dom";
import {Header} from "../components/Header";

export const Setting = ()=> {
    const navigate = useNavigate();

    return <div className={"information"}>
        <Header></Header>
        <div className={"setting-page"}>
            <Button variant="contained" style={{margin:10,width:"100%",border:"5px solid #fff",borderRadius:10,backgroundColor:"#12977A"}} onClick={()=>{
                const keys = Object.keys(localStorage);
                keys.forEach(key=>{
                    if(key.includes("SCAN2BIM")){
                        localStorage.removeItem(key)
                    }
                })
            }
            }>Reset Scan2BIm</Button>
            <Button variant="contained" style={{margin:10,width:"100%",border:"5px solid #fff",borderRadius:10,backgroundColor:"#12977A"}} onClick={()=>{
                    const keys = Object.keys(localStorage);
                    keys.forEach(key=>{
                        if(key.includes("SCAN2Progress")){
                            localStorage.removeItem(key)
                        }
                    })
            }
            }>Reset Scan2Progress</Button>
        </div>

    </div>
}