import { Canvas, useLoader } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from "react";
import * as THREE from "three";

export const Model = (props) => {
  const modelCenter = () => {
    if (props.modelName === "Zustand_2") {
      return [-1, 0, 0];
    }
    if (
      props.modelName === "Fensterelement" ||
      props.modelName === "Wandelement" ||
      props.modelName === "Tuerelement"
    )
      return [-0.7, 1, 0];
    if (props.modelName === "2er_Bodenplatte") {
      return [0, 1, 0];
    }
    if (props.modelName === "3er_Bodenplatte") {
      return [1, 2.6, 0];
    }
    if (props.modelName === "Dachelement") {
      return [1, 2.6, 0];
    }
    if (props.modelName === "Giebelelement") {
      return [1, 5.7, 0];
    }
    if (props.modelName === "Eckbauteil") {
      return [1, 2.6, 0];
    }
    if (props.modelName === "Verbindungsbauteile_Typ_1") {
      return [0, -3, 0];
    }
    if (props.modelName === "Verbindungsbauteile_Typ_2") {
      return [-1, -3, 0];
    }
  };
  const modelRotation = () => {
    if (props.modelName === "Zustand_2") {
      return [0, -1, -1];
    }
    if (props.modelName === "Zustand_3") {
      return [0, -1, -1];
    }
    if (props.modelName === "Zustand_4") {
      return [0, -1, -1];
    }
    if (props.modelName === "Zustand_5") {
      return [0, -1, -1];
    }
    if (props.modelName === "Zustand_1") {
      return [0, -1, -1];
    }
    if (
      props.modelName === "Fensterelement" ||
      props.modelName === "Wandelement" ||
      props.modelName === "Tuerelement"
    )
      return [-1.1, 0, 0.2];
    if (props.modelName === "2er_Bodenplatte") {
      return [-2.8, -0.1, -0.1];
    }
    if (props.modelName === "3er_Bodenplatte") {
      return [-2.8, -0.1, -0.1];
    }
    if (props.modelName === "Dachelement") {
      return [-2.8, -0.1, -0.1];
    }
    if (props.modelName === "Eckbauteil") {
      return [-2.8, -0.1, -0.1];
    }
    if (props.modelName === "Giebelelement") {
      return [-1.8, 0.2, -0.3];
    }
    if (props.modelName === "Verbindungsbauteile_Typ_1") {
      return [-0.4, -0.9, 0];
    }
    if (props.modelName === "Verbindungsbauteile_Typ_2") {
      return [-0.4, -0.9, 0];
    }

    return [-1, -1, 0];
  };
  const modelZoom = () => {
    if (props.modelName === "2er_Bodenplatte") return 0.3;
    if (props.modelName === "Dachelement") {
      return 1.1;
    }
    if (props.modelName === "Eckbauteil") {
      return 5;
    }
    if (props.modelName === "Verbindungsbauteile_Typ_2") {
      return 0.3;
    }
    if (
      props.modelName === "Zustand_2" ||
      props.modelName === "Zustand_1" ||
      props.modelName === "Zustand_3" ||
      props.modelName === "Zustand_4" ||
      props.modelName === "Zustand_5"
    ) {
      return 0.25;
    }
    return 0.4;
  };
  const ThreeModel = () => {
    const { scene, materials } = useLoader(
      GLTFLoader,
      `/3Dmodels/${props.modelName}.glb`,
      onprogress
    );
    scene.add(new THREE.AmbientLight(0x333));

    return (
      <Suspense fallback={null}>
        <primitive
          object={scene}
          scale={modelZoom()}
          rotation={modelRotation()}
        />
        <Environment preset={"dawn"}></Environment>
      </Suspense>
    );
  };
  //
  return (
    <div className="Model">
      <Canvas style={{ backgroundColor: "#d8e5f6" }}>
        <Suspense fallback={null}>
          <ThreeModel />
          <OrbitControls target={modelCenter()} />
        </Suspense>
      </Canvas>
    </div>
  );
};
