import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = () => {
    const locations = location.pathname.split("/");
    locations.pop();
    const oldPath = locations.join("/");
    if (oldPath === "") navigate("/");
    else if (oldPath.includes("information")) {
      navigate("/");
    } else navigate(oldPath);
  };
  return (
    <div className={"Header"}>
      <div className={"header-style"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            goBack();
          }}
        >
          <ArrowBackIosIcon></ArrowBackIosIcon> Back
        </div>
        {location.pathname.includes("information") ? (
          <div
            onClick={() => {
              if (location.pathname.includes("SCAN2BIM")) navigate("/scan2BIM");
              else navigate("/scan2Progress");
            }}
          >
            <CameraAltIcon
              style={{ color: "#12977A", fontSize: 33, marginRight: 10 }}
            ></CameraAltIcon>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
