import Lego from "../images/Logo.png"
import Button from "@mui/material/Button"
import SettingsIcon from '@mui/icons-material/Settings';
import {useNavigate} from "react-router-dom";

export const StartPage = ()=> {
    const navigate = useNavigate();

    return <div>
        <div className={"start-page-setting"}>
            <SettingsIcon style={{fontSize:40,float:"right",margin:"5px",color:"#12977A"}} onClick={()=>{
                navigate("/settings")}
            }></SettingsIcon>
        </div>
        <div className={"lego-container"}>
            <img src={Lego} alt="Logo" height={"100%"} width={"100%"}/>;
        </div>

        <div className={"start-page-buttons"}>
            <Button variant="contained" style={{margin:10,width:"100%",border:"5px solid #fff",borderRadius:10,backgroundColor:"#12977A"}} onClick={()=>{
                navigate("/scan2BIM")
            }
            }>Scan2BIM</Button>
            <Button variant="contained" style={{margin:10,width:"100%",border:"5px solid #fff",borderRadius:10,backgroundColor:"#12977A"}} onClick={()=>{
                navigate("/scan2Progress")
            }
            }>Scan2Progress</Button>
        </div>

    </div>
}