import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import AddSharpIcon from "@mui/icons-material/AddSharp";

export const Details = (props) => {
  const [add, setAdd] = useState(false);
  const sortingArr = [
    "ID",
    "Größe",
    "Farbe",
    "Material",
    "Anzahl der Löcher",
    "Anzahl der Verbinder",
    "Kosten",
    "Anzahl der Vertiefung/ Einpressung",
    "Zugehörigkeit zu Bauprozess",
    "Vorbedingungen für Bauteilmontage",
    "Besonderheiten",
    "ID Bauprozess",
    "Name",
    "Verbaute Bauteile von Zustand",
    "Insgesamt verbaute Bauteile",
  ];

  useEffect(() => {
    setAdd(false);
  }, [props.data]);

  const informations = Object.keys(props.data)
    .map((key) => {
      return { key, value: props.data[key] };
    })
    .filter((keyValue) => {
      return keyValue.key !== "type" && keyValue.key !== "label";
    })
    .sort((a, b) => {
      if (a.key.includes("Verbaute Bauteile von Zustand")) {
        a.key = a.key.split("Zustand")[0].concat("Zustand");
      }
      if (b.key.includes("Verbaute Bauteile von Zustand")) {
        b.key = b.key.split("Zustand")[0].concat("Zustand");
      }
      return sortingArr.indexOf(a.key) - sortingArr.indexOf(b.key);
    });

  const createFixedRows = (data) => {
    return data.map((keyValue, index) => {
      return (
        <div className={"details-row"} key={index}>
          <div className={"details-row-left"}>
            <p
              style={{
                backgroundColor: index % 2 != 0 ? "transparent" : "#D0EAE4",
              }}
            >
              {keyValue.key}
            </p>
          </div>
          <div className={"details-row-right"}>
            <p
              style={{
                backgroundColor: index % 2 != 0 ? "transparent" : "#D0EAE4",
              }}
            >
              {keyValue.key.includes("Größe")
                ? keyValue.value.split("|").map((m) => {
                  return <p>{m}</p>;
                })
                : keyValue.key.includes("Verbaute Bauteile")
                  ? keyValue.value.join(", ")
                  : keyValue.value}
            </p>
          </div>
        </div>
      );
    });
  };

  const createEditableRows = (data) => {
    return data.map((keyValue, index) => {
      return (
        <div className={"details-row"} key={index}>
          <div className={"details-row-left"}>
            <p
              style={{
                backgroundColor: index % 2 != 0 ? "transparent" : "#D0EAE4",
              }}
            >
              {keyValue.key}
            </p>
          </div>
          <TextField
            className={"details-row-right"}
            style={{
              backgroundColor: index % 2 != 0 ? "transparent" : "#D0EAE4",
            }}
            value={props.data[keyValue.key]}
            onChange={(e) => {
              const newCurrentInformation = { ...props.data };
              newCurrentInformation[keyValue.key] = e.target.value;
              props.setNewInformations(newCurrentInformation);
            }}
          ></TextField>
        </div>
      );
    });
  };

  const addNewElement = () => {
    return (
      <div className={"details-row"}>
        <TextField
          className={"details-row-right"}
          style={{ backgroundColor: "#D0EAE4" }}
          value={props.newKey}
          onChange={(e) => {
            props.setNewKey(e.target.value);
          }}
        ></TextField>
        <TextField
          className={"details-row-right"}
          style={{ backgroundColor: "#D0EAE4" }}
          value={props.newValue}
          onChange={(e) => {
            props.setNewValue(e.target.value);
          }}
        ></TextField>
      </div>
    );
  };

  return (
    <div className={"details"}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!props.editable
          ? createFixedRows(informations)
          : createEditableRows(informations)}
        {add ? addNewElement() : ""}
        {props.editable ? (
          <AddSharpIcon
            style={{
              backgroundColor: "#D0EAE4",
              height: "40px",
              width: "40px",
              marginTop: "10px",
            }}
            onClick={() => {
              setAdd(true);
            }}
          ></AddSharpIcon>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
