import axios from "axios";

const BASE_URL = "";

export const getInformation = (label, labelType) => {
  return axios.get(
    BASE_URL + "/information?label=" + label + "&labelType=" + labelType
  );
};

export const classifyImage = (capturedImage, mode) => {
  return axios.post(BASE_URL + "/predict", { img: capturedImage, mode });
};
