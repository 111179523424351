import {Header} from "../../components/Header";
import {WebcamComponent} from "../../components/Webcam";
import {useEffect, useState} from "react";
import {classifyImage} from "../../api/requests";
import {useNavigate} from "react-router-dom";

export const Scan2BIM = () => {
    const navigate = useNavigate();

    const [capturedImage, setCapturedImage] = useState(null)

    useEffect(() => {
        if (capturedImage) {
            classifyImage(capturedImage, "SCAN_2BIM").then((data) => {
               const label = data.data["label"].replaceAll(" ","_");
               const probability = Math.floor(data.data.probability*100);
               navigate("/information/"+"SCAN2BIM&"+label+"&"+probability)
            })
        }
    }, [capturedImage])

    return <>
        <div className={"Scan"}>
        <Header></Header>
            <WebcamComponent setCapturedImage={setCapturedImage}></WebcamComponent>
        </div>
    </>
}