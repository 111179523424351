import React from "react";
import Webcam from "react-webcam";
import CircleIcon from '@mui/icons-material/Circle';

export const WebcamComponent = (props) => {
    const webcamRef = React.useRef(null);
    const videoConstraints = {
        facingMode:"environment",
        width: 1280,
        height: 720,
    };
    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        props.setCapturedImage(imageSrc)
    }, [webcamRef]);

    return (
        <>
            <Webcam
                style={{height:"100%"}}
                audio={false}
                ref={webcamRef}
                id="img"
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
            />
            <CircleIcon className={"camera-button"} style={{fontSize:100,position:"absolute",top:"80%",color:"#12977A",border:"5px solid #fff",padding:0,borderRadius:100}} onClick={()=>{
                capture()
            }}></CircleIcon>
        </>
    );
};
